.vue-notification {
    padding: 10px;
    margin: 0 5px 5px;
    font-size: 20px;
    color: #ffffff;
    background: #47afbf;
    box-shadow: 0px 0px 5px black;

    &.warn {
        background: #ffb648;
        border-left-color: #f48a06;
    }

    &.error {
        background: #E54D42;
        border-left-color: #B82E24;
    }

    &.success {
        background: #68CD86;
        border-left-color: #42A85F;
    }
}